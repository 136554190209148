@import "assets/scss/vars.scss";

.filter_range {
	padding: 5px 10px;

	:global {
		.p-slider {
			height: 2px !important;
			background-color: #eeeeee !important;
			border-radius: 2px;
			-webkit-border-radius: 2px;
			-moz-border-radius: 2px;
			-ms-border-radius: 2px;
			-o-border-radius: 2px;

			&-range {
				background-color: $mainColor !important;
			}

			&-handle {
				width: 24px;
				height: 24px;
				margin-top: 0 !important;
				transform: translateY(-50%);
				-webkit-transform: translateY(-50%);
				-moz-transform: translateY(-50%);
				-ms-transform: translateY(-50%);
				-o-transform: translateY(-50%);
				background-color: $mainColor;
				box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
				border-radius: 4px;
				-webkit-border-radius: 4px;
				-moz-border-radius: 4px;
				-ms-border-radius: 4px;
				-o-border-radius: 4px;
				border-color: $mainColor;
				background-image: url("~/public/img/slider-arrow.svg");
				background-repeat: no-repeat;
				background-position: center;
				background-size: cover;
				padding: 7px;

				&:hover {
					background-color: $mainColor !important;
					border-color: $mainColor !important;
					background-image: url("~/public/img/slider-arrow.svg") !important;
					background-repeat: no-repeat !important;
					background-position: center !important;
					background-size: cover !important;
				}

				&:focus {
					box-shadow: 0 0 0 0.2rem #ffd6e2 !important;
				}
			}
		}
	}

	.from_to {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 5px;
		margin-top: 15px;

		#{$rtl} & {
			direction: ltr;
		}

		&_box {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			gap: 5px;

			.val {
				font-size: 14px;
				font-weight: 700;
				color: #101223;
			}

			.txt {
				font-size: 12px;
				font-weight: 400;
				color: #969696;
			}
		}
	}

	.filter_val {
		font-size: 14px;
		font-weight: 600;
		color: $mainColor;
		padding: 5px;
		border: 1px dashed $mainColor;
		background-color: $whiteColor;
		min-width: 30px;
		height: 30px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-shrink: 0;
	}
}
