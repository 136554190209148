@import "assets/scss/vars.scss";

.customer_slider {
	:global {
		.p-carousel {
			direction: ltr;
		}

		.p-carousel-prev,
		.p-carousel-next {
			&:not(.p-disabled) {
				color: $mainDarkColor;
			}
		}

		.p-carousel-indicator {
			margin-right: 4px !important;
			margin-bottom: 4px !important;

			.p-link {
				width: 12px !important;
				height: 8px !important;
				background-color: $whiteColor !important;
				border: 1px solid #dce0e6 !important;
				box-shadow: 0px 0px 4px rgba(67, 185, 51, 0.16) !important;
				border-radius: 12px !important;
				-webkit-border-radius: 12px !important;
				-moz-border-radius: 12px !important;
				-ms-border-radius: 12px !important;
				-o-border-radius: 12px !important;
				transition: 0.3s;
				-webkit-transition: 0.3s;
				-moz-transition: 0.3s;
				-ms-transition: 0.3s;
				-o-transition: 0.3s;
			}

			&.p-highlight {
				.p-link {
					background-color: $mainColor !important;
				}
			}
		}
	}
}

.customer_card {
	background-color: $whiteColor;
	box-shadow: 0px 6px 16px rgba(22, 25, 102, 0.08);
	border-radius: 10px;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	-ms-border-radius: 10px;
	-o-border-radius: 10px;
	padding: 30px;
	position: relative;
	min-height: 262px;
	margin: 0 80px;

	#{$rtl} & {
		text-align: right;
	}

	@media (max-width: 991.98px) {
		margin: 0 60px;
	}

	@media (max-width: 575.98px) {
		margin: 60px 0 0;
	}

	.customer_img {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 152px;
		height: 152px;
		border-radius: 100%;
		-webkit-border-radius: 100%;
		-moz-border-radius: 100%;
		-ms-border-radius: 100%;
		-o-border-radius: 100%;
		overflow: hidden;
		// border: 8px solid rgba(218, 218, 218, 0.2);
		-webkit-filter: drop-shadow(0px 6px 16px rgba(22, 25, 102, 0.08));
		filter: drop-shadow(0px 6px 16px rgba(22, 25, 102, 0.08));
		position: absolute;
		left: 0;
		top: 50%;
		transform: translate(-50%, -50%);
		-webkit-transform: translate(-50%, -50%);
		-moz-transform: translate(-50%, -50%);
		-ms-transform: translate(-50%, -50%);
		-o-transform: translate(-50%, -50%);
		-webkit-filter: drop-shadow(0px 6px 16px rgba(22, 25, 102, 0.08));

		#{$rtl} & {
			right: 0;
			left: auto;
			transform: translate(50%, -50%);

			@media (max-width: 575.98px) {
				right: 50%;
			}
		}

		@media (max-width: 991.98px) {
			width: 145px;
			height: 145px;
		}

		@media (max-width: 575.98px) {
			top: 0;
			left: 50%;
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	.card_txt {
		width: 100%;
		max-width: calc(100% - 122px);
		margin-inline-start: auto;
		margin-inline-end: 30px;

		#{$rtl} & {
			margin-inline-end: auto;
			margin-inline-start: 30px;

			@media (max-width: 575.98px) {
				max-width: calc(100% - 30px);
				margin: 0 auto;
				margin-top: 50px;
			}
		}

		@media (max-width: 575.98px) {
			max-width: calc(100% - 30px);
			margin: 0 auto;
			margin-top: 50px;
		}

		h4 {
			font-size: 20px;
			font-weight: 700;
			color: $mainDarkColor;
			margin-bottom: 30px;
			text-transform: capitalize;
		}

		p {
			font-size: 20px;
			font-weight: 400;
			color: $bodyTextColor;
			letter-spacing: 2px;
			margin-bottom: 0;

			q {
				position: relative;
				display: block;

				&::before,
				&::after {
					color: $mainColor;
					font-size: 44px;
					font-weight: 700;
				}

				&::before {
					position: absolute;
					left: -30px;
					top: -30px;
				}

				&::after {
					position: absolute;
					right: -30px;
					bottom: -30px;
				}
			}
		}
	}
}
