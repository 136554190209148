@import "assets/scss/vars.scss";

.field_holder {
	h6 {
		font-size: 16px;
		font-weight: 600;
		color: $grayColor;
		margin-bottom: 10px;
	}

	:global {
		.p-inputtext {
			background-color: #fff !important;
			border: 0;
			box-shadow: 0px 6px 16px rgba(22, 25, 102, 0.08);
			border-radius: 10px;
			-webkit-border-radius: 10px;
			-moz-border-radius: 10px;
			-ms-border-radius: 10px;
			-o-border-radius: 10px;
		}
	}
}
