@import "assets/scss/vars.scss";

.purchase_card {
	position: relative;
	margin-bottom: 25px;

	:global(.purchase_item) {
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		gap: 7px;
		background-color: $whiteColor;
		border: 1px solid $SecondaryLightGrayColor;
		border-radius: 8px;
		-webkit-border-radius: 8px;
		-moz-border-radius: 8px;
		-ms-border-radius: 8px;
		-o-border-radius: 8px;
		overflow: hidden;
		margin: 0 6px;

		.img {
			flex-shrink: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 103px;
			height: 87px;
			overflow: hidden;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		.info {
			padding: 5px 5px 5px 0;

			.name {
				font-size: 14px;
				font-weight: 600;
				color: $textDarkColor;
				margin-bottom: 5px;
			}

			.installment {
				font-size: 12px;
				font-weight: 600;
				color: $textDarkColor;
				margin-bottom: 8px;
			}

			.rest_price {
				font-size: 12px;
				font-weight: 700;
				color: $mainColor;
			}
		}
	}

	:global {
		.p-carousel {
			direction: ltr;

			&-prev,
			&-next {
				width: 44px !important;
				height: 44px !important;
				color: $textDarkColor !important;
				border-radius: 100%;
				-webkit-border-radius: 100%;
				-moz-border-radius: 100%;
				-ms-border-radius: 100%;
				-o-border-radius: 100%;
				background-color: $whiteColor !important;
				box-shadow: 0px 2px 20px 8px rgba($mainColor, 0.15);
				margin: 2px !important;
				position: absolute;
				z-index: 5;
				opacity: 1;
				visibility: visible;
				margin: 0 !important;
				transition: 0.3s;
				-webkit-transition: 0.3s;
				-moz-transition: 0.3s;
				-ms-transition: 0.3s;
				-o-transition: 0.3s;
				top: 50%;
				transform: translateY(-50%);
				-webkit-transform: translateY(-50%);
				-moz-transform: translateY(-50%);
				-ms-transform: translateY(-50%);
				-o-transform: translateY(-50%);

				&.p-disabled {
					opacity: 0;
					visibility: hidden;
				}
			}

			&-prev {
				left: 0;
			}

			&-next {
				right: 0;
			}
		}
	}
}
