@import "assets/scss/vars.scss";

.dialog_box {
	@media (max-width: 1399.98px) {
		width: 60vw !important;
	}

	@media (max-width: 1024.98px) {
		width: 70vw !important;
	}

	@media (max-width: 575.98px) {
		width: 90vw !important;
	}

	:global {
		.p-dialog-header {
			padding: 20px 24px;
			position: relative;

			&::before {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background: linear-gradient(
					to bottom,
					rgba($mainColor, 0.1) -30.15%,
					rgba($mainColor, 0) 93.22%
				);
			}

			@media (max-width: 575.98px) {
				padding: 15px 10px;
			}

			.p-dialog-title {
				font-size: 18px;
				font-weight: 700;
				color: $mainColor;
				position: relative;
			}

			.p-dialog-header-icons {
				position: relative;

				.p-dialog-header-close {
					width: 24px;
					height: 24px;
					font-size: 12px;
					font-weight: 700;
					color: $mainColor;

					&-icon {
						font-size: 12px;
						font-weight: 700;
						color: $mainColor;
					}

					&:hover {
						background-color: rgba($mainColor, 0.15);
					}
				}
			}
		}

		.p-dialog-content {
			padding: 15px 24px;

			@media (max-width: 575.98px) {
				padding: 15px;
			}
		}

		.p-dialog-footer {
			padding: 20px 24px;

			@media (max-width: 575.98px) {
				padding: 15px 10px;
			}
		}
	}
}
