@import "assets/scss/vars.scss";

.auth_layout {
	background-color: $whiteColor;
	position: relative;

	.app_logo {
		position: absolute;
		top: 32px;
		display: flex;
		width: 100%;
		max-width: 147px;
		z-index: 5;

		#{$ltr} & {
			left: 93px;

			@media (max-width: 575.98px) {
				left: 20px;
			}
		}
		#{$rtl} & {
			right: 93px;

			@media (max-width: 575.98px) {
				right: 20px;
			}
		}

		img {
			width: 100%;
		}
	}

	.auth_langBtn {
		position: absolute;
		top: 32px;
		z-index: 5;

		#{$ltr} & {
			right: 93px;

			@media (max-width: 575.98px) {
				right: 20px;
			}
		}
		#{$rtl} & {
			left: 93px;

			@media (max-width: 575.98px) {
				left: 20px;
			}
		}
	}
}
