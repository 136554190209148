@import "assets/scss/vars.scss";

.app_header {
	padding: 22px 0;
	background-color: rgba($whiteColor, 0.2);
	-webkit-backdrop-filter: blur(3px);
	backdrop-filter: blur(3px);
	position: relative;
	z-index: 999;

	&_content {
		display: flex;
		align-items: center;
		justify-content: space-between;

		.app_logo {
			flex-shrink: 0;
			display: flex;
			align-items: center;
			justify-content: flex-start;
			width: 100%;
			max-width: 147px;

			img {
				width: 100%;
			}
		}

		.header_left {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			gap: 44px;
		}

		.menu_links {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			gap: 24px;

			.menu_link {
				a {
					font-size: 16px;
					font-weight: 600;
					color: $mainDarkColor;
					letter-spacing: 0.2em;
					text-transform: capitalize;
					transition: 0.3s;
					-webkit-transition: 0.3s;
					-moz-transition: 0.3s;
					-ms-transition: 0.3s;
					-o-transition: 0.3s;

					&:hover {
						color: $mainColor;
					}
				}
			}
		}

		.header_btns {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			gap: 24px;
		}
	}

	&.logged_header {
		.header_left {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			gap: 48px;
		}

		.logged_actions {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			gap: 24px;

			.icon_btn {
				padding: 0;
				background-color: transparent;
				border: 0;
				box-shadow: none;
				color: #000;
				font-size: 0;
			}
		}

		.user_dropdown {
			position: relative;

			.menu_btn {
				padding: 0;
				background-color: transparent;
				border: 0;
				box-shadow: none;
				color: $mainDarkColor;
				display: flex;
				align-items: center;
				justify-content: flex-start;
				gap: 10px;

				.profile_pic {
					flex-shrink: 0;
					display: flex;
					align-items: center;
					justify-content: center;
					width: 36px;
					height: 36px;
					overflow: hidden;
					border-radius: 100%;
					-webkit-border-radius: 100%;
					-moz-border-radius: 100%;
					-ms-border-radius: 100%;
					-o-border-radius: 100%;
					border: 1px solid rgba(255, 88, 135, 0.1);

					img {
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
				}

				.name {
					font-size: 14px;
					font-weight: 700;
					color: $mainDarkColor;
					text-transform: capitalize;
				}
			}

			:global {
				.p-menu {
					&-overlay {
						top: calc(100% + 2px) !important;
						left: 0 !important;
						// width: 100%;
						width: auto;

						.p-menuitem {
							&-link {
								color: $mainDarkColor !important;
								box-shadow: none;

								&:hover {
									background-color: rgba($mainColor, 0.1);
								}

								&:focus {
									box-shadow: none;
								}
							}

							&-icon {
								#{$rtl} & {
									margin-right: 0 !important;
									margin-left: 0.5rem;
								}
							}
						}
					}
				}
			}
		}
	}
}

.lng_burger {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	gap: 20px;
}

.openSidebarBtn {
	width: 40px;
	height: 40px;
	border-radius: 100%;
	-webkit-border-radius: 100%;
	-moz-border-radius: 100%;
	-ms-border-radius: 100%;
	-o-border-radius: 100%;
	background: linear-gradient(to bottom, $mainColor 0%, $darkBlueColor 100%);
	display: flex;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;
	font-size: 0;
	border: 0;
}

// Responsive Header
.responsive_header {
	position: fixed;
	top: 0;
	#{$ltr} & {
		right: 0;
	}
	#{$rtl} & {
		left: 0;
	}
	height: 100vh;
	width: 0%;
	z-index: 999;
	background: linear-gradient(to bottom, $mainColor 0%, $darkBlueColor 100%);
	opacity: 0;
	visibility: hidden;
	transition: 0.3s;
	-webkit-transition: 0.3s;
	-moz-transition: 0.3s;
	-ms-transition: 0.3s;
	-o-transition: 0.3s;
	padding: 20px;
	display: flex;
	flex-direction: column;

	&.show {
		width: 100%;
		opacity: 1;
		visibility: visible;
	}

	.closeSidebarBtn {
		width: 40px;
		height: 40px;
		border-radius: 100%;
		-webkit-border-radius: 100%;
		-moz-border-radius: 100%;
		-ms-border-radius: 100%;
		-o-border-radius: 100%;
		border: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #fff !important;
		color: $mainColor !important;
		margin-bottom: 20px;
	}

	.sidebar_content {
		flex-grow: 1;
		overflow-x: hidden;
		overflow-y: auto;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 30px;

		.menu_links {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			gap: 24px;

			.menu_link {
				a {
					font-size: 16px;
					font-weight: 600;
					color: $whiteColor;
					letter-spacing: 0.2em;
					text-transform: capitalize;
					transition: 0.3s;
					-webkit-transition: 0.3s;
					-moz-transition: 0.3s;
					-ms-transition: 0.3s;
					-o-transition: 0.3s;

					&:hover {
						color: $whiteColor;
					}
				}
			}
		}

		.header_btns {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			gap: 24px;
		}
	}

	&.responsive_logged_header {
		.logged_actions {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			gap: 24px;

			.icon_btn {
				padding: 0;
				background-color: transparent;
				border: 0;
				box-shadow: none;
				color: #000;
				font-size: 0;
			}
		}

		.user_dropdown {
			position: relative;

			.menu_btn {
				padding: 0;
				background-color: transparent;
				border: 0;
				box-shadow: none;
				color: $whiteColor;
				display: flex;
				align-items: center;
				justify-content: flex-start;
				gap: 10px;

				.profile_pic {
					flex-shrink: 0;
					display: flex;
					align-items: center;
					justify-content: center;
					width: 36px;
					height: 36px;
					overflow: hidden;
					border-radius: 100%;
					-webkit-border-radius: 100%;
					-moz-border-radius: 100%;
					-ms-border-radius: 100%;
					-o-border-radius: 100%;
					border: 1px solid rgba(255, 88, 135, 0.1);

					img {
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
				}

				.name {
					font-size: 14px;
					font-weight: 700;
					color: $whiteColor;
					text-transform: capitalize;
				}
			}

			:global {
				.p-menu {
					&-overlay {
						top: calc(100% + 2px) !important;
						left: 0 !important;
						width: 100%;

						.p-menuitem {
							&-link {
								color: $mainDarkColor !important;
								box-shadow: none;

								&:hover {
									background-color: rgba($mainColor, 0.1);
								}

								&:focus {
									box-shadow: none;
								}
							}

							&-icon {
								#{$rtl} & {
									margin-right: 0 !important;
									margin-left: 0.5rem;
								}
							}
						}
					}
				}
			}
		}
	}
}
