@import "assets/scss/vars.scss";

.prodcuts_section {
	padding: 20px 0 100px;
	position: relative;

	.productsSec_content {
		position: relative;

		h2 {
			font-size: 44px;
			font-weight: 700;
			color: $mainColor;
			text-align: center;
			text-transform: capitalize;
			margin-bottom: 8px;
		}

		p {
			font-size: 16px;
			font-weight: 400;
			color: $blueTextBodyColor;
			text-align: center;
			letter-spacing: 2px;
			max-width: 38%;
			margin: 0 auto 75px;
			line-height: 28px;

			@media (max-width: 991.98px) {
				max-width: 70%;
			}

			@media (max-width: 575.98px) {
				max-width: 100%;
			}
		}
	}
}
