@import "assets/scss/vars.scss";

.offer_carousel {
	direction: ltr;

	:global {
		.p-galleria {
			&-content {
				gap: 12px;

				.p-galleria-item-wrapper {
					width: 100%;

					.p-galleria-item-container {
						width: 100%;

						.p-galleria-item {
							background-color: $whiteColor;
							padding: 58px 30px;
							border-radius: 4px;
							-webkit-border-radius: 4px;
							-moz-border-radius: 4px;
							-ms-border-radius: 4px;
							-o-border-radius: 4px;
							overflow: hidden;
							height: 554px;

							@media (max-width: 1024.98px) {
								height: 875px;
							}

							@media (max-width: 575.98px) {
								height: 405px;
							}
						}
					}
				}

				.p-galleria-thumbnail-wrapper {
					.p-galleria-thumbnail-container {
						background-color: transparent !important;
						padding: 0 !important;

						.p-galleria-thumbnail-items-container {
							.p-galleria-thumbnail-items {
								gap: 24px;

								@media (max-width: 1400.98px) {
									gap: 20px;
								}

								@media (max-width: 575.98px) {
									gap: 15px;
								}

								.p-galleria-thumbnail-item {
									width: 173px;
									height: 136px;
									overflow: hidden;
									background-color: $whiteColor;
									border-radius: 4px;
									-webkit-border-radius: 4px;
									-moz-border-radius: 4px;
									-ms-border-radius: 4px;
									-o-border-radius: 4px;
									opacity: 1;
									border: 1px solid transparent;
									transition: 0.3s;
									-webkit-transition: 0.3s;
									-moz-transition: 0.3s;
									-ms-transition: 0.3s;
									-o-transition: 0.3s;
									flex-basis: 30.5%;

									@media (max-width: 1024.98px) {
										height: 226px;
									}

									@media (max-width: 992.98px) {
										height: 190px;
									}

									@media (max-width: 575.98px) {
										height: 130px;
										flex-basis: 46.5%;
									}

									&-content {
										width: 100%;
										height: 100%;
										padding: 19px 34px;

										&:focus {
											box-shadow: none !important;
										}

										img {
											width: 100%;
											height: 100%;
											object-fit: cover;
										}
									}

									&-current {
										border-color: $mainColor;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
