@import "assets/scss/vars.scss";

*,
*::before,
*::after {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	line-height: normal;
}

* {
	font-family: "Mulish", sans-serif, "primeicons" !important;
}

html {
	scroll-behavior: smooth;
}

body {
	font-family: "Mulish", sans-serif, "primeicons" !important;
	overflow-x: hidden;
	padding: 0;
	margin: 0;
	text-align: start;
	min-height: 100vh;
}

#root {
	height: 100vh;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
	padding: 0;
	margin: 0;
}

a {
	text-decoration: none !important;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	-ms-transition: all 0.3s;
	-o-transition: all 0.3s;
	transition: all 0.3s;
}

*::placeholder {
	font-size: 14px;
	font-weight: 400;
	color: $lightGrayColor;
	transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
}

ul {
	padding: 0;
	margin: 0;
	list-style: none;
}

/* Custom Scroll bar */
body::-webkit-scrollbar,
*::-webkit-scrollbar {
	width: 0.5em;
	height: 0.5em;
}

body::-webkit-scrollbar-track,
*::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	background-color: $mainColor;
}

body::-webkit-scrollbar-thumb,
*::-webkit-scrollbar-thumb {
	background-color: $darkBlueColor;
	outline: 1px solid $mainColor;
}

/* custom scrollbar for firefox */
@-moz-document url-prefix() {
	*,
	html,
	body {
		/* "auto" or "thin"  */
		scrollbar-width: thin;
		/* scroll thumb & track */
		scrollbar-color: $darkBlueColor $mainColor;
	}
}

// My Style
.App {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	background-color: #fff;
	overflow: hidden;
}

main {
	flex-grow: 1;
	flex-shrink: 1;
	flex-basis: auto;
}

// Input Holder
.input_holder {
	display: flex;
	flex-direction: column;
	gap: 5px;

	&:not(:last-child) {
		margin-bottom: 30px;
	}

	.p-inputtext:enabled:focus {
		box-shadow: 0px 6px 16px rgba(22, 25, 102, 0.08);
		border: 0;
	}

	.p-inputtext:enabled:hover {
		border: 0;
	}
}

input {
	font-size: 14px !important;
	font-weight: 400 !important;
	color: $lightGrayColor !important;
	caret-color: $mainColor;
}

.p-dropdown {
	&.p-focus {
		box-shadow: none;
	}
}

// Inputs Auto Fill
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
	-webkit-box-shadow: 0px 6px 16px rgba(22, 25, 102, 0.08),
		0 0 0px 1000px #fff inset !important;
	box-shadow: 0px 6px 16px rgba(22, 25, 102, 0.08), 0 0 0px 1000px #fff inset !important;
	background-color: #fff !important;
}

// Button
button {
	&:focus {
		box-shadow: none !important;
	}
}

// Table
.p-datatable .p-datatable-thead > tr > th,
.p-datatable .p-datatable-tbody > tr > td {
	text-align: start !important;
}

// Dialog
.p-dialog {
	@media (max-width: 575.98px) {
		width: 90vw !important;
	}
}
