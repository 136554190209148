@import "assets/scss/vars.scss";

.breadCrumb {
	background-color: transparent;
	border: 0;
	padding: 5px 0;
	margin-bottom: 22px;

	:global {
		ul {
			li {
				&.p-breadcrumb-chevron {
					&:first-child {
						display: none;
					}
					font-size: 10px;
					color: $gray500Color;
					transition: 0.3s;
					-webkit-transition: 0.3s;
					-moz-transition: 0.3s;
					-ms-transition: 0.3s;
					-o-transition: 0.3s;

					#{$rtl} & {
						transform: rotate(180deg);
						-webkit-transform: rotate(180deg);
						-moz-transform: rotate(180deg);
						-ms-transform: rotate(180deg);
						-o-transform: rotate(180deg);
					}
				}

				.p-menuitem-link {
					.p-menuitem-text {
						font-size: 14px;
						font-weight: 600;
						color: $bodyTextMutedColor;
					}
				}

				&:last-child {
					.p-menuitem-link {
						.p-menuitem-text {
							color: $darkColor;
						}
					}
				}
			}
		}
	}
}
