@import "assets/scss/vars.scss";

.offer_slider {
	:global {
		.p-carousel {
			direction: ltr;

			&-item {
				flex: 0 0 auto !important;
			}
		}

		.p-carousel-prev,
		.p-carousel-next {
			&:not(.p-disabled) {
				color: $mainDarkColor;
			}
		}

		.p-carousel-indicator {
			margin-right: 4px !important;
			margin-bottom: 4px !important;

			.p-link {
				width: 12px !important;
				height: 8px !important;
				background-color: $whiteColor !important;
				border: 1px solid #dce0e6 !important;
				box-shadow: 0px 0px 4px rgba(67, 185, 51, 0.16) !important;
				border-radius: 12px !important;
				-webkit-border-radius: 12px !important;
				-moz-border-radius: 12px !important;
				-ms-border-radius: 12px !important;
				-o-border-radius: 12px !important;
				transition: 0.3s;
				-webkit-transition: 0.3s;
				-moz-transition: 0.3s;
				-ms-transition: 0.3s;
				-o-transition: 0.3s;
			}

			&.p-highlight {
				.p-link {
					background-color: $mainColor !important;
				}
			}
		}
	}

	[class*="product_card"] {
		margin: 0 10px 20px;
	}
}
