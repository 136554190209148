@keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  .spinner {
    border: 5px solid rgba(255, 255, 255, 0.2); 
    border-top: 5px solid #3498db; 
    border-radius: 50%; 
    width: 50px;
    height: 50px; 
    animation: spin 1s linear infinite; 
    margin: 20px auto; 
  }