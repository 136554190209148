@import "assets/scss/vars.scss";

.custom_radioBtn {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;
	cursor: pointer;

	input[type="radio"] {
		display: none;
	}

	.txt {
		padding: 12px 25px;
		font-size: 16px;
		font-weight: 700;
		color: $mainDarkColor;
		border: 1px solid transparent;
		background: rgba(27, 29, 77, 0.1);
		border-radius: 4px;
		-webkit-border-radius: 4px;
		-moz-border-radius: 4px;
		-ms-border-radius: 4px;
		-o-border-radius: 4px;
		transition: 0.3s;
		-webkit-transition: 0.3s;
		-moz-transition: 0.3s;
		-ms-transition: 0.3s;
		-o-transition: 0.3s;

		&.installment_txt {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			padding: 4px 14px;
		}
	}

	input:checked + .txt {
		background: rgba($mainColor, 0.1);
		border-color: $mainColor;
		color: $mainColor;
	}
}
