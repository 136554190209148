@import "assets/scss/vars.scss";

.how_it_work {
	padding: 64px 0;
	background-color: $lightGoldColor;

	h2 {
		font-size: 44px;
		font-weight: 700;
		color: $mainDarkColor;
		text-transform: capitalize;
		margin-bottom: 59px;
	}

	.hr_line {
		width: 100%;
		max-width: 172px;
		border-bottom: 2px solid $mainColor;
		margin-bottom: 35px;
	}

	.how_box {
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		gap: 16px;

		.num {
			flex-shrink: 0;
			font-size: 44px;
			font-weight: 700;
			color: $mainColor;
			text-transform: capitalize;
			line-height: 48px;
		}

		.txt {
			font-size: 16px;
			font-weight: 400;
			color: $bodyTextColor;
			letter-spacing: 2px;
			line-height: 28px;
			word-break: break-word;
		}
	}
}
