@import "assets/scss/vars.scss";

.edit_dialog {
	:global {
		.p-dialog-content {
			overflow: hidden;
			padding: 0;
		}
	}
}

.edit_user {
	display: flex;
	flex-direction: column;
	height: calc(100vh - 174px);

	.from_inputs {
		flex-grow: 1;
		overflow-x: hidden;
		overflow-y: auto;
		padding: 0 24px 32px;
	}

	.upload_img {
		position: relative;
		width: 186px;
		height: 186px;
		border-radius: 100%;
		-webkit-border-radius: 100%;
		-moz-border-radius: 100%;
		-ms-border-radius: 100%;
		-o-border-radius: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0 auto;
		cursor: pointer;

		input[type="file"] {
			display: none;
		}

		.img_holder {
			width: 186px;
			height: 186px;
			border-radius: 100%;
			-webkit-border-radius: 100%;
			-moz-border-radius: 100%;
			-ms-border-radius: 100%;
			-o-border-radius: 100%;
			overflow: hidden;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-shrink: 0;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		.cam_icon {
			position: absolute;
			width: 44px;
			height: 44px;
			border-radius: 100%;
			-webkit-border-radius: 100%;
			-moz-border-radius: 100%;
			-ms-border-radius: 100%;
			-o-border-radius: 100%;
			background-color: $goldColor;
			display: flex;
			align-items: center;
			justify-content: center;
			bottom: 0;
			right: 0;
			transform: translateX(-50%);
			-webkit-transform: translateX(-50%);
			-moz-transform: translateX(-50%);
			-ms-transform: translateX(-50%);
			-o-transform: translateX(-50%);
			transform-origin: bottom right;
			transition: 0.3s;
			-webkit-transition: 0.3s;
			-moz-transition: 0.3s;
			-ms-transition: 0.3s;
			-o-transition: 0.3s;
		}

		&:hover {
			.cam_icon {
				right: 50%;
				bottom: 50%;
				transform: translate(50%, 50%);
				-webkit-transform: translate(50%, 50%);
				-moz-transform: translate(50%, 50%);
				-ms-transform: translate(50%, 50%);
				-o-transform: translate(50%, 50%);
			}
		}
	}

	.form_btns {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		gap: 10px;
		padding: 24px;
	}
}
