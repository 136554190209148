@import "assets/scss/vars.scss";

.creditUpdates_box {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	gap: 12px;
	padding: 17px 21px;
	background-color: $whiteColor;
	box-shadow: 0px 2px 20px 8px rgba(51, 55, 165, 0.05);
	border-radius: 5px;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	-ms-border-radius: 5px;
	-o-border-radius: 5px;

	.icon {
		flex-shrink: 0;
		width: 100%;
		max-width: 32px;
	}

	.credit_data {
		.val {
			font-size: 20px;
			font-weight: 700;
			color: $blackColor;
			margin-bottom: 3px;
		}

		.tit {
			font-size: 14px;
			font-weight: 400;
			color: #979797;
			letter-spacing: -0.3px;
		}
	}
}
