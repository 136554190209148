@import "assets/scss/vars.scss";

.reviews_holder {
	.col_tit {
		font-size: 18px;
		font-weight: 700;
		color: $textColor;
		text-transform: capitalize;
	}

	.verified_ratings {
		.col_tit {
			margin-bottom: 22px;
		}

		.total_rates {
			@media (max-width: 992.98px) {
				display: flex;
				align-items: flex-start;
				justify-content: space-between;
				gap: 10px;
			}

			@media (max-width: 575.98px) {
				flex-direction: column;
			}
		}

		.total_rate {
			padding: 26px 23px;
			background-color: $midGrayColor;
			border-radius: 8px;
			-webkit-border-radius: 8px;
			-moz-border-radius: 8px;
			-ms-border-radius: 8px;
			-o-border-radius: 8px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			gap: 12px;
			width: 173px;
			max-width: 100%;
			height: 154px;
			margin-bottom: 20px;

			[class*="rate_holder"] {
				flex-direction: column;
				gap: 5px;

				[class*="rate_value"] {
					font-size: 25px;
					font-weight: 800;
					color: #fdaf30;
				}
			}

			.whoRate_num {
				font-size: 16px;
				font-weight: 500;
				color: $textDarkColor;
				text-align: center;
			}
		}

		.rates {
			.rate_row {
				&:not(:last-child) {
					margin-bottom: 20px;
				}
			}
		}
	}

	.product_reviews {
		.col_head {
			display: flex;
			align-items: center;
			justify-content: space-between;
			gap: 10px;
			margin-bottom: 22px;

			.see_all {
				padding: 4px 12px;
				background: rgba($mainColor, 0.1);
				border-radius: 4px;
				-webkit-border-radius: 4px;
				-moz-border-radius: 4px;
				-ms-border-radius: 4px;
				-o-border-radius: 4px;
				border: 1px solid transparent;
				font-size: 14px;
				font-weight: 700;
				color: $mainColor;
				transition: 0.3s;
				-webkit-transition: 0.3s;
				-moz-transition: 0.3s;
				-ms-transition: 0.3s;
				-o-transition: 0.3s;

				&:hover {
					border-color: $mainColor;
				}
			}
		}

		.reviews_rows {
			margin-bottom: 15px;
			height: auto;
			max-height: 430px;
			overflow: hidden;
			transition: 0.3s ease-in-out;
			-webkit-transition: 0.3s ease-in-out;
			-moz-transition: 0.3s ease-in-out;
			-ms-transition: 0.3s ease-in-out;
			-o-transition: 0.3s ease-in-out;

			&.heightAuto {
				max-height: 1500px;
			}
		}

		.write_review {
			&_tit {
				font-size: 18px;
				font-weight: 700;
				color: $textColor;
				margin-bottom: 15px;
			}

			&_form {
				border: 1px solid $yellowColor;
				border-radius: 8px;
				-webkit-border-radius: 8px;
				-moz-border-radius: 8px;
				-ms-border-radius: 8px;
				-o-border-radius: 8px;
				padding: 24px 18px;

				.form_head {
					display: flex;
					align-items: center;
					justify-content: space-between;
					margin-bottom: 20px;

					@media (max-width: 575.98px) {
						flex-direction: column;
						align-items: flex-start;
						gap: 20px;
					}

					.select_rate {
						.tit {
							font-size: 14px;
							font-weight: 600;
							color: $darkColor;
							margin-bottom: 14px;
						}
					}

					.form_btns {
						display: flex;
						align-items: center;
						justify-content: flex-end;
						gap: 16px;
					}
				}
			}
		}
	}
}
