@import "assets/scss/vars.scss";

.field_holder {
	h6 {
		font-size: 16px;
		font-weight: 600;
		color: $grayColor;
		margin-bottom: 10px;
	}

	:global {
		.p-inputtext {
			background-color: #fff !important;
			border: 0;
			box-shadow: 0px 6px 16px rgba(22, 25, 102, 0.08);
			border-radius: 10px;
			-webkit-border-radius: 10px;
			-moz-border-radius: 10px;
			-ms-border-radius: 10px;
			-o-border-radius: 10px;

			#{$rtl} & {
				padding-right: 12px;
				padding-left: 2.5rem;
			}
		}

		.p-password {
			.p-inputtext {
				width: 100%;
			}
		}

		.p-input-icon-right > i:last-of-type,
		.p-input-icon-right > svg:last-of-type,
		.p-input-icon-right > .p-input-suffix {
			#{$rtl} & {
				right: auto !important;
				left: 0.75rem;
			}
		}
	}
}
