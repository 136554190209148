@import "assets/scss/vars.scss";

.category_card {
	display: block;
	width: 100%;
	background-color: $whiteColor;
	box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
	border-radius: 12px;
	-webkit-border-radius: 12px;
	-moz-border-radius: 12px;
	-ms-border-radius: 12px;
	-o-border-radius: 12px;
	overflow: hidden;
	margin-bottom: 32px;

	.cat_img {
		width: 100%;
		height: 230px;
		overflow: hidden;
		background: linear-gradient(
			0deg,
			rgba($mainColor, 0.1) -30.15%,
			rgba($mainColor, 0) 93.22%
		);
		padding: 10px;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			transition: 0.3s;
			-webkit-transition: 0.3s;
			-moz-transition: 0.3s;
			-ms-transition: 0.3s;
			-o-transition: 0.3s;
		}
	}

	.cat_name {
		padding: 20px 16px;
		font-family: "Mada", sans-serif !important;
		font-size: 16px;
		font-weight: 700;
		color: #080913;
	}

	&:hover {
		.cat_img {
			img {
				transform: scale(1.1);
				-webkit-transform: scale(1.1);
				-moz-transform: scale(1.1);
				-ms-transform: scale(1.1);
				-o-transform: scale(1.1);
			}
		}
	}
}
