@import "assets/scss/vars.scss";

.textarea_holder {
	display: flex;
	flex-direction: column;
	gap: 8px;

	.field_holder {
		display: flex;
		flex-direction: column;
		gap: 8px;

		h6 {
			font-size: 14px;
			font-weight: 600;
			color: $darkColor;
		}

		.textArea_input {
			display: flex;
			width: 100%;
			height: 96px;
			padding: 10px;
			overflow-y: auto;
			overflow-x: hidden;
			background: $whiteColor;
			border-radius: 6px;
			-webkit-border-radius: 6px;
			-moz-border-radius: 6px;
			-ms-border-radius: 6px;
			-o-border-radius: 6px;
			border: 1px solid $yellowColor;
			box-shadow: none;
			font-size: 14px;
			font-weight: 500;
			color: $darkColor;

			&::placeholder {
				font-size: 14px;
				font-weight: 600;
				color: $gray500Color;
			}
		}
	}
}
