@import "assets/scss/vars.scss";

.product_card {
	background: $whiteColor;
	box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
	border-radius: 12px;
	-webkit-border-radius: 12px;
	-moz-border-radius: 12px;
	-ms-border-radius: 12px;
	-o-border-radius: 12px;
	overflow: hidden;
	margin-bottom: 25px;
	// width: fit-content;

	#{$rtl} & {
		direction: rtl;
	}

	.pro_img {
		width: 100%;
		height: 230px;
		overflow: hidden;
		display: flex;
		align-items: center;
		justify-content: center;
		background: linear-gradient(
			0deg,
			rgba($mainColor, 0.1) -30.15%,
			rgba($mainColor, 0) 93.22%
		);
		padding: 30px;
		position: relative;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			transition: 0.3s;
			-webkit-transition: 0.3s;
			-moz-transition: 0.3s;
			-ms-transition: 0.3s;
			-o-transition: 0.3s;
		}

		.discount {
			position: absolute;
			background: rgba($mainColor, 0.1);
			-webkit-backdrop-filter: blur(2px);
			backdrop-filter: blur(2px);
			border-radius: 50px;
			-webkit-border-radius: 50px;
			-moz-border-radius: 50px;
			-ms-border-radius: 50px;
			-o-border-radius: 50px;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 4px 10px;
			top: 16px;
			#{$ltr} & {
				left: 16px;
			}
			#{$rtl} & {
				right: 16px;
			}
			font-size: 14px;
			font-weight: 400;
			color: $mainColor;
		}
	}

	&_body {
		padding: 20px 16px;

		.product_name {
			font-size: 16px;
			font-weight: 700;
			color: #080913;
			margin-bottom: 15px;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			overflow: hidden;
			height: 39px;
			line-height: normal;
			transition: 0.3s;
			-webkit-transition: 0.3s;
			-moz-transition: 0.3s;
			-ms-transition: 0.3s;
			-o-transition: 0.3s;
		}

		.price_rate {
			display: flex;
			align-items: center;
			justify-content: space-between;
			gap: 5px;
			margin-bottom: 16px;

			.price {
				display: flex;
				align-items: center;
				justify-content: flex-start;
				gap: 8px;

				.afterDiscount {
					font-size: 16px;
					font-weight: 700;
					color: #080913;
				}

				.original_price {
					font-size: 14px;
					font-weight: 400;
					color: #969696;
					text-decoration: line-through;
				}
			}

			.rate {
				display: flex;
				align-items: center;
				justify-content: flex-start;
				gap: 4px;

				span {
					font-size: 14px;
					font-weight: 700;
					color: #8392a5;
				}

				img {
					flex-shrink: 0;
					width: 100%;
					max-width: 24px;
				}
			}
		}

		.card_btns {
			display: flex;
			align-items: center;
			justify-content: space-between;
			gap: 8px;
		}
	}

	&:hover {
		.pro_img {
			img {
				transform: scale(1.1);
				-webkit-transform: scale(1.1);
				-moz-transform: scale(1.1);
				-ms-transform: scale(1.1);
				-o-transform: scale(1.1);
			}
		}

		.product_name {
			color: $mainColor;
			text-decoration: underline !important;
		}
	}
}
