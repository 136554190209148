@import "assets/scss/vars.scss";

.search_input {
	:global {
		.p-input-icon-right {
			width: 100%;

			.p-inputtext {
				background-color: $whiteColor;
				box-shadow: 0px 6px 16px rgba(22, 25, 102, 0.08);
				border-radius: 10px;
				-webkit-border-radius: 10px;
				-moz-border-radius: 10px;
				-ms-border-radius: 10px;
				-o-border-radius: 10px;
				border: 0;

				#{$rtl} & {
					padding-right: 12px;
					padding-left: 2.5rem;
				}

				&::placeholder {
					font-size: 14px;
					font-weight: 400;
					color: #969696;
				}
			}

			i {
				color: #969696;

				&:last-of-type {
					#{$rtl} & {
						right: auto;
						left: 0.75rem;
					}
				}
			}
		}
	}
}
