@import "assets/scss/vars.scss";

.document_box {
	&:not(:last-child) {
		padding-bottom: 18px;
		margin-bottom: 16px;
		border-bottom: 1px solid $SecondaryLightGrayColor;
	}

	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	gap: 12px;

	.doc_img {
		flex-shrink: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		@include borderRadius(8px);
		width: 122px;
		height: 87px;
		position: relative;

		input[type="file"] {
			display: none;
		}

		&_box {
			flex-shrink: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 122px;
			height: 87px;
			border: 1px solid $SecondaryLightGrayColor;
			@include borderRadius(8px);
			overflow: hidden;
			position: relative;

			&::before {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: rgba(0, 0, 0, 0.3);
				opacity: 0;
				visibility: hidden;
				@include transition(0.3s);
			}

			.img {
				// width: 100%;
				height: 80%;
				-o-object-fit: cover;
				object-fit: cover;
			}
		}

		.plus_icon {
			position: absolute;
			width: 20px;
			height: 20px;
			@include borderRadius(100%);
			background-color: $goldColor;
			display: flex;
			align-items: center;
			justify-content: center;
			top: 0;
			#{$ltr} & {
				left: 0;
				@include transform(translate(-50%, -50%));
			}
			#{$rtl} & {
				right: 0;
				@include transform(translate(50%, -50%));
			}
			@include transition(0.3s);

			i {
				font-size: 12px;
				color: $mainColor;
			}
		}

		&:hover {
			cursor: pointer;

			.doc_img_box {
				&::before {
					opacity: 1;
					visibility: visible;
				}
			}

			.plus_icon {
				top: 50%;
				#{$ltr} & {
					left: 50%;
					@include transform(translate(-50%, -50%));
				}
				#{$rtl} & {
					right: 50%;
					@include transform(translate(50%, -50%));
				}
			}
		}
	}

	.doc_info {
		width: 100%;

		.doc_name {
			font-size: 16px;
			font-weight: 600;
			color: $textDarkColor;
			margin-bottom: 8px;
		}

		.size_status {
			display: flex;
			align-items: center;
			justify-content: space-between;
			gap: 10px;
			margin-bottom: 11px;

			.size {
				font-size: 12px;
				font-weight: 700;
				color: $textDarkColor;
			}
		}

		.doc_btns {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			gap: 4px;

			.doc_btn {
				flex-shrink: 0;
				display: flex;
				align-items: center;
				justify-content: center;
				background: rgba($mainColor, 0.1);
				border-radius: 4px;
				-webkit-border-radius: 4px;
				-moz-border-radius: 4px;
				-ms-border-radius: 4px;
				-o-border-radius: 4px;
				color: $mainColor;
				padding: 0;
				border: 1px solid transparent;
				transition: 0.3s;
				-webkit-transition: 0.3s;
				-moz-transition: 0.3s;
				-ms-transition: 0.3s;
				-o-transition: 0.3s;

				img {
					max-width: 100%;
				}

				&:hover {
					border-color: $mainColor;
				}
			}
		}
	}
}
