@import "assets/scss/vars.scss";

.lang_btn {
	background-color: transparent !important;
	color: $secondaryColor !important;
	border: 0;
	padding: 0;
	width: 50px;
	height: 50px;
	background-image: url("~/public/img/lang-circle.svg") !important;
	background-repeat: no-repeat !important;
	background-position: center !important;
	background-size: cover !important;
	font-size: 14px;
	font-weight: 600;

	@media (max-width: 991.98px) {
		width: 40px;
		height: 40px;
		font-size: 12px;
	}
}
