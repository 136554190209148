@import "assets/scss/vars.scss";

.product_carousel {
	:global {
		.p-galleria {
			&-content {
				gap: 27px;

				.p-galleria-item-wrapper {
					width: 100%;

					.p-galleria-item-container {
						width: 100%;

						.p-galleria-item {
							background-color: #f1f1f1;
							padding: 27px;
							border-radius: 4px;
							-webkit-border-radius: 4px;
							-moz-border-radius: 4px;
							-ms-border-radius: 4px;
							-o-border-radius: 4px;
							overflow: hidden;
						}
					}
				}

				.p-galleria-thumbnail-wrapper {
					flex-shrink: 0;

					.p-galleria-thumbnail-container {
						background-color: transparent !important;
						padding: 0 !important;

						.p-galleria-thumbnail-items-container {
							// height: 760px !important;
							overflow-y: auto;
							overflow-x: hidden;
							margin-inline-end: 5px;

							@media (max-width: 767.98px) {
								height: auto !important;
								max-width: 100%;
								overflow-y: hidden;
								overflow-x: auto;
							}

							&::-webkit-scrollbar {
								width: 0.2em;
								height: 0.2em;
							}
							.p-galleria-thumbnail-items{
								height: auto;
							}

							.p-galleria-thumbnail-item {
								width: 172px;
								height: 151px;
								overflow: hidden;
								margin-bottom: 39px;
								border: 1px solid #f0f0f0;
								padding: 23px 26px;
								opacity: 1 !important;
								flex: 1 0 20% !important;
								border-radius: 4px;
								-webkit-border-radius: 4px;
								-moz-border-radius: 4px;
								-ms-border-radius: 4px;
								-o-border-radius: 4px;
								transition: 0.3s;
								-webkit-transition: 0.3s;
								-moz-transition: 0.3s;
								-ms-transition: 0.3s;
								-o-transition: 0.3s;

								@media (max-width: 767.98px) {
									margin-bottom: 0;
									flex: 1 0 172px !important;
								}

								&-content {
									width: 100%;
									height: 100%;

									&:focus {
										box-shadow: none !important;
									}

									img {
										width: 100%;
										height: 100%;
										object-fit: cover;
									}
								}

								&-current {
									border-color: $mainColor;
								}
							}
						}
					}
				}
			}
		}
	}
}
