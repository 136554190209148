@import "assets/scss/vars.scss";

.app_footer {
	background-color: rgba($whiteColor, 0.2);
	-webkit-backdrop-filter: blur(3px);
	backdrop-filter: blur(3px);
	padding: 30px 0;

	.footer_content {
		.top_footer {
			padding: 80px 0;

			.footer_col {
				.footer_logo {
					width: 100%;
					max-width: 223px;
					display: flex;
					align-items: center;
					justify-content: center;
					margin-bottom: 50px;

					img {
						width: 100%;
					}
				}

				.app_desc {
					font-size: 16px;
					font-weight: 400;
					color: $blueTextBodyColor;
					margin-bottom: 30px;
					letter-spacing: 2px;
					line-height: 28px;
				}

				.btns_holder {
					display: flex;
					align-items: center;
					justify-content: flex-start;
					gap: 15px;

					.app_store_btn {
						flex-shrink: 0;
						display: flex;
						align-items: center;
						justify-content: center;
						padding: 7px;
						border: 0;
						background-color: $goldColor;
						box-shadow: 1px 7px 19px rgba(51, 51, 51, 0.08);
						border-radius: 6px;
						-webkit-border-radius: 6px;
						-moz-border-radius: 6px;
						-ms-border-radius: 6px;
						-o-border-radius: 6px;
						font-size: 0;

						img {
							width: 100%;
						}
					}
				}

				h5 {
					font-size: 24px;
					font-weight: 700;
					color: $mainDarkColor;
					text-transform: capitalize;
					margin-bottom: 50px;
				}

				.footer_links {
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					justify-content: flex-start;
					gap: 20px;

					a {
						font-size: 18px;
						font-weight: 400;
						color: $blueTextBodyColor;
						transition: 0.3s;
						-webkit-transition: 0.3s;
						-moz-transition: 0.3s;
						-ms-transition: 0.3s;
						-o-transition: 0.3s;

						&:hover {
							color: $mainColor;
						}
					}
				}

				&.last_col {
					height: 100%;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					gap: 38px;

					.last_footer_links {
						display: flex;
						align-items: center;
						justify-content: center;

						a {
							font-size: 14px;
							font-weight: 400;
							color: $blueTextBodyColor;
							transition: 0.3s;
							-webkit-transition: 0.3s;
							-moz-transition: 0.3s;
							-ms-transition: 0.3s;
							-o-transition: 0.3s;
							padding: 0 15px;

							&:hover {
								color: $mainColor;
							}

							&:last-child {
								border-inline-start: 2px solid $blueTextBodyColor;
							}
						}
					}

					.social_links {
						display: flex;
						align-items: center;
						justify-content: center;
						gap: 30px;

						.social_link {
							flex-shrink: 0;
							width: 32px;
							height: 32px;
							border-radius: 100%;
							-webkit-border-radius: 100%;
							-moz-border-radius: 100%;
							-ms-border-radius: 100%;
							-o-border-radius: 100%;
							padding: 2px;
							background-color: $mainColor;
							display: flex;
							align-items: center;
							justify-content: center;
							position: relative;

							img {
								width: auto;
								max-width: 100%;
							}

							&::before {
								content: "";
								position: absolute;
								top: 50%;
								left: 50%;
								width: 40px;
								height: 40px;
								border-radius: 100%;
								-webkit-border-radius: 100%;
								-moz-border-radius: 100%;
								-ms-border-radius: 100%;
								-o-border-radius: 100%;
								transform: translate(-50%, -50%);
								-webkit-transform: translate(-50%, -50%);
								-moz-transform: translate(-50%, -50%);
								-ms-transform: translate(-50%, -50%);
								-o-transform: translate(-50%, -50%);
								border: 2px solid transparent;
								transition: 0.3s;
								-webkit-transition: 0.3s;
								-moz-transition: 0.3s;
								-ms-transition: 0.3s;
								-o-transition: 0.3s;
							}

							&:hover::before {
								border-color: $mainColor;
							}
						}
					}
				}
			}
		}

		.copyRights {
			font-size: 14px;
			font-weight: 600;
			color: $mainDarkColor;
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
		}
	}
}
