@import "assets/scss/vars.scss";

.our_solutions {
	padding: 80px 0;
	position: relative;

	.ellipses1 {
		position: absolute;
		top: 50%;
		left: 0;
		transform: translateY(-50%);
		-webkit-transform: translateY(-50%);
		-moz-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		-o-transform: translateY(-50%);
		max-height: 100%;
		height: 312px;
	}

	h2 {
		font-size: 44px;
		font-weight: 700;
		color: $mainColor;
		text-transform: capitalize;
		margin-bottom: 70px;
	}

	.solution_cards {
		margin-bottom: 73px;

		@media (max-width: 991.98px) {
			margin-bottom: 30px;
		}

		.solution_card {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;

			.img {
				height: 216px;
				max-width: 100%;
				margin-bottom: 26px;

				img {
					height: 100%;
				}
			}

			h5 {
				font-size: 20px;
				font-weight: 700;
				color: $mainDarkColor;
				margin-bottom: 16px;
				text-align: center;
				text-transform: capitalize;
			}

			p {
				font-size: 16px;
				font-weight: 400;
				color: $bodyTextColor;
				margin-bottom: 0;
				text-align: center;
				letter-spacing: 2px;
			}
		}
	}
}
