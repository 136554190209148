@import "assets/scss/vars.scss";

.intro_section {
	padding: 64px 0;

	@media (max-width: 575.98px) {
		padding: 30px 0;
	}

	h1 {
		font-size: 44px;
		font-weight: 700;
		color: $mainDarkColor;
		line-height: 48px;
		text-transform: capitalize;
		margin-bottom: 16px;

		@media (max-width: 575.98px) {
			font-size: 30px;
		}
	}

	p {
		font-size: 24px;
		font-weight: 400;
		color: $bodyTextColor;
		letter-spacing: 2px;
		margin-bottom: 32px;

		@media (max-width: 575.98px) {
			font-size: 20px;
		}
	}

	.btns_holder {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		gap: 25px;
		margin-bottom: 32px;

		.app_store_btn {
			padding: 10px;
			background-color: $SecondaryLightGrayColor;
			border-radius: 6px;
			-webkit-border-radius: 6px;
			-moz-border-radius: 6px;
			-ms-border-radius: 6px;
			-o-border-radius: 6px;
			border: 0;
			box-shadow: none;
		}
	}

	[class*="gradient_btn"] {
		img {
			transition: 0.3s;
			-webkit-transition: 0.3s;
			-moz-transition: 0.3s;
			-ms-transition: 0.3s;
			-o-transition: 0.3s;

			#{$rtl} & {
				transform: rotate(180deg);
				-webkit-transform: rotate(180deg);
				-moz-transform: rotate(180deg);
				-ms-transform: rotate(180deg);
				-o-transform: rotate(180deg);
			}
		}
	}

	.intro_img {
		display: block;
		width: 100%;
	}
}
