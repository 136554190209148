@import "assets/scss/vars.scss";

%status {
	display: flex;
	align-items: center;
	justify-content: center;
	width: -moz-fit-content;
	width: fit-content;
	padding: 4px 16px;
	border-radius: 5px;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	-ms-border-radius: 5px;
	-o-border-radius: 5px;
	font-size: 12px;
	font-weight: 500;
	text-align: center;
	letter-spacing: -0.0257895em;
}

.status {
	flex-shrink: 0;

	:global {
		.pending {
			@extend %status;
			background-color: #fdfde7;
			color: #e3b829;
		}

		.done {
			@extend %status;
			background-color: #e7fdf3;
			color: #7bdba8;
		}

		.completed {
			@extend %status;
			background-color: #e7fdf3;
			color: #7bdba8;
		}
	}
}
