@import "assets/scss/vars.scss";

.product_details {
	.pro_details_top {
		margin-bottom: 160px;

		.carousel_info {
			margin-bottom: 38px;
		}
	}
}
