@import "assets/scss/vars.scss";

.gradient_btn {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	padding: 12px 24px;
	background-color: $mainColor;
	box-shadow: 0px 6px 16px rgba(22, 25, 102, 0.08);
	border-radius: 4px;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	-ms-border-radius: 4px;
	-o-border-radius: 4px;
	font-size: 16px;
	font-weight: 600;
	color: $whiteColor !important;
	border: 1px solid #fff;
	white-space: nowrap;
	gap: 15px;

	:global {
		.p-button-label {
			font-size: 16px;
			font-weight: 600;
			color: $whiteColor;
			white-space: nowrap;
			flex-grow: unset;
		}
	}

	&:hover,
	&:enabled:hover {
		background-color: $mainColor;
		box-shadow: 0px 6px 16px rgba(22, 25, 102, 0.26);
		border-color: #fff;
	}
}
