@import "assets/scss/vars.scss";

.fawry_payment {
	.head {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		gap: 80px;
		margin-bottom: 16px;

		h4 {
			font-size: 16px;
			font-weight: 700;
			color: $mainDarkColor;
			margin-bottom: 0;
		}

		.copy_btn {
			flex-shrink: 0;
			padding: 0;
			border: 0;
			background-color: transparent;
			width: 24px;
			height: 24px;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 0;
			color: $mainDarkColor;

			img {
				max-width: 100%;
			}
		}
	}

	.msg {
		font-size: 16px;
		font-weight: 400;
		color: $textLightDarkColor;
		line-height: 32px;
		margin-bottom: 0;
	}
}
