@import "assets/scss/vars.scss";

.dark_btn {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 6px;
	padding: 12px 24px;
	border: 1px solid transparent;
	background-color: $mainColor;
	color: $whiteColor;
	font-size: 16px;
	font-weight: 700;
	border-radius: 4px;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	-ms-border-radius: 4px;
	-o-border-radius: 4px;
	box-shadow: none !important;
	width: -moz-fit-content;
	width: fit-content;
	transition: 0.3s;
	-webkit-transition: 0.3s;
	-moz-transition: 0.3s;
	-ms-transition: 0.3s;
	-o-transition: 0.3s;

	&:hover {
		background-color: $mainColor !important;
		color: $whiteColor !important;
		box-shadow: 0px 6px 16px rgba(22, 25, 102, 0.26) !important;
		border-color: transparent !important;
	}

	&.outlinedBtn {
		background-color: $whiteColor !important;
		border-color: $bodyTextColor !important;
		color: $bodyTextColor !important;
		transition: 0.3s;
		-webkit-transition: 0.3s;
		-moz-transition: 0.3s;
		-ms-transition: 0.3s;
		-o-transition: 0.3s;

		&:hover {
			background-color: $whiteColor !important;
			color: $bodyTextColor !important;
			box-shadow: 0px 6px 16px rgba(22, 25, 102, 0.26) !important;
		}
	}

	.btn_img {
		order: -1;
	}
}
