@import "assets/scss/vars.scss";

.purchase_offers {
	background-color: $whiteColor;
	border: 1px solid #f1f5fd;
	border-radius: 8px;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	-ms-border-radius: 8px;
	-o-border-radius: 8px;
	padding: 24px;
	height: 100%;

	:global {
		.p-tabview {
			.p-tabview-nav-container {
				margin-bottom: 10px;

				.p-tabview-nav-content {
					.p-tabview-nav {
						border: 0;
						gap: 30px;

						.p-unselectable-text {
							.p-tabview-nav-link {
								padding: 0;
								outline: 0 none;
								box-shadow: none;
								font-size: 18px;
								font-weight: 700;
								color: $textColor;
								text-transform: capitalize;
							}

							&.p-highlight {
								.p-tabview-nav-link {
									color: $mainColor;
								}
							}
						}

						.p-tabview-ink-bar {
							display: none;
						}
					}
				}
			}

			.p-tabview-panels {
				padding: 20px 10px;
			}
		}
	}
}
