@import "assets/scss/vars.scss";

.review_box {
	&:not(:last-child) {
		margin-bottom: 30px;
	}

	.review_head {
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
		gap: 10px;
		margin-bottom: 14px;

		.reviewer_info {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			gap: 8px;

			.img {
				flex-shrink: 0;
				display: flex;
				align-items: center;
				justify-content: center;
				width: 46px;
				height: 46px;
				overflow: hidden;

				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}

			.info {
				.name {
					font-size: 14px;
					font-weight: 700;
					color: $textDarkColor;
					margin-bottom: 6px;
				}
			}
		}

		.time {
			font-size: 16px;
			font-weight: 400;
			color: $textDarkColor;
		}
	}

	.review_txt {
		font-size: 14px;
		font-weight: 400;
		color: $textDarkColor;
		line-height: 22px;
		margin-bottom: 5px;
	}

	.verified_purchase {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		gap: 5px;

		.icon {
			flex-shrink: 0;
			width: 16px;
			height: 16px;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 100%;
			-webkit-border-radius: 100%;
			-moz-border-radius: 100%;
			-ms-border-radius: 100%;
			-o-border-radius: 100%;
			background-color: $greenColor;
			padding: 4px;

			img {
				width: 100%;
				max-width: 100%;
			}
		}

		.txt {
			font-size: 14px;
			font-weight: 600;
			color: $greenColor;
		}
	}
}
