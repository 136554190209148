@import "assets/scss/vars.scss";

.offers_table {
	:global {
		.p-datatable {
			margin-bottom: 24px;

			&-wrapper {
				&::-webkit-scrollbar {
					width: 0.2em;
					height: 0.2em;
				}
			}

			&-table {
				border-collapse: separate;
				border-spacing: 0 8px;

				.p-datatable-thead {
					tr {
						th {
							background-color: transparent;
							border: 0;

							.p-column-header-content {
								.p-column-title {
									font-size: 14px;
									font-weight: 700;
									color: $blackColor;
								}
							}
						}
					}
				}

				.p-datatable-tbody {
					tr {
						td {
							font-size: 14px;
							font-weight: 400;
							color: $blackColor;
							border-top: 1px solid $SecondaryLightGrayColor;
							border-bottom: 1px solid $SecondaryLightGrayColor;

							&:first-child {
								border-inline-start: 1px solid $SecondaryLightGrayColor;
								#{$ltr} & {
									border-top-left-radius: 8px;
									border-bottom-left-radius: 8px;
								}
								#{$rtl} & {
									border-top-right-radius: 8px;
									border-bottom-right-radius: 8px;
								}
							}

							&:last-child {
								border-inline-end: 1px solid $SecondaryLightGrayColor;
								#{$ltr} & {
									border-top-right-radius: 8px;
									border-bottom-right-radius: 8px;
								}
								#{$rtl} & {
									border-top-left-radius: 8px;
									border-bottom-left-radius: 8px;
								}
							}
						}
					}
				}
			}
		}
	}
}
