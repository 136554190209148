@import "assets/scss/vars.scss";

.installment_payNow {
	.purchase_details {
		border: 1px solid $SecondaryLightGrayColor;
		border-radius: 8px;
		-webkit-border-radius: 8px;
		-moz-border-radius: 8px;
		-ms-border-radius: 8px;
		-o-border-radius: 8px;
		padding: 16px;

		h3 {
			font-size: 16px;
			font-weight: 600;
			color: $textColor;
			margin-bottom: 16px;
		}

		.purchase_item {
			display: flex;
			align-items: flex-start;
			justify-content: flex-start;
			gap: 12px;
			margin-bottom: 40px;

			.img {
				flex-shrink: 0;
				width: 83px;
				height: 70px;
				background: linear-gradient(
					179.39deg,
					rgba(255, 255, 255, 0) 0.53%,
					rgba($mainColor, 0.086) 63.65%
				);
				border-radius: 8px;
				-webkit-border-radius: 8px;
				-moz-border-radius: 8px;
				-ms-border-radius: 8px;
				-o-border-radius: 8px;
				display: flex;
				align-items: center;
				justify-content: center;
				overflow: hidden;

				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}

			.item_info {
				.name {
					font-size: 14px;
					font-weight: 600;
					color: $textDarkColor;
					margin-bottom: 5px;
				}

				.qty {
					font-size: 14px;
					font-weight: 400;
					color: $textDarkColor;
				}
			}
		}

		.total {
			padding-top: 12px;
			border-top: 1px solid rgba($mainColor, 0.1);
			display: flex;
			align-items: center;
			justify-content: space-between;
			gap: 10px;

			&_label {
				font-size: 12px;
				font-weight: 600;
				color: $textDarkColor;
			}

			&_val {
				font-size: 12px;
				font-weight: 700;
				color: $mainColor;
			}
		}
	}

	.payment {
		h3 {
			font-size: 16px;
			font-weight: 700;
			color: $textColor;
			margin-bottom: 17px;
		}

		.payment_choices {
			.choice {
				&:not(:last-child) {
					margin-bottom: 19px;
				}

				background-color: $whiteColor;
				border: 1px solid $SecondaryLightGrayColor;
				border-radius: 8px;
				-webkit-border-radius: 8px;
				-moz-border-radius: 8px;
				-ms-border-radius: 8px;
				-o-border-radius: 8px;

				.fawry_choice,
				.card_choice {
					padding: 15px 15px 24px;
				}

				.fawry_choice {
					[class*="field_holder"] {
						:global {
							.p-inputtext {
								box-shadow: none;
								border: 1px solid $SecondaryLightGrayColor;
								border-radius: 10px;
								-webkit-border-radius: 10px;
								-moz-border-radius: 10px;
								-ms-border-radius: 10px;
								-o-border-radius: 10px;
							}
						}
					}
				}

				.card_choice {
					[class*="field_holder"] {
						:global {
							.p-inputtext {
								box-shadow: none;
								border: 1px solid $SecondaryLightGrayColor;
								border-radius: 10px;
								-webkit-border-radius: 10px;
								-moz-border-radius: 10px;
								-ms-border-radius: 10px;
								-o-border-radius: 10px;
							}
						}
					}
				}
			}
		}
	}
}
