@import "assets/scss/vars.scss";

.partners {
	padding: 30px 0 124px;
	position: relative;

	.ellipses1 {
		position: absolute;
		top: 0;
		left: 0;
		transform: translateY(-50%);
		-webkit-transform: translateY(-50%);
		-moz-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		-o-transform: translateY(-50%);
		height: 423px;
	}

	&_content {
		position: relative;

		h2 {
			font-size: 44px;
			font-weight: 700;
			color: $mainColor;
			text-align: center;
			text-transform: capitalize;
			margin-bottom: 16px;
		}

		h5 {
			font-size: 16px;
			font-weight: 600;
			color: $mainDarkColor;
			text-align: center;
			margin-bottom: 4px;
		}

		p {
			font-size: 16px;
			font-weight: 400;
			color: $blueTextBodyColor;
			text-align: center;
			letter-spacing: 2px;
			max-width: 38%;
			margin: 0 auto 21px;
			line-height: 28px;

			@media (max-width: 991.98px) {
				max-width: 70%;
			}

			@media (max-width: 575.98px) {
				max-width: 100%;
			}
		}

		.hr_line {
			width: 370px;
			max-width: 95%;
			border-bottom: 1px solid $darkBlueColor;
			margin: 0 auto 77px;
		}

		.partners_imgs {
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 51px;
			flex-wrap: wrap;

			.partner_img {
				flex-shrink: 0;
				height: 48px;
			}
		}
	}
}
