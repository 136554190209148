@import "assets/scss/vars.scss";

.home_banner {
	padding: 10px 0;

	[class*="bannar"] {
		min-height: 400px;
		margin-bottom: 0;
	}
}
