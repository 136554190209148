@import "assets/scss/vars.scss";

.pagination {
	:global {
		.p-paginator {
			background-color: transparent;
			color: $darkGrayColor;
			padding: 0;

			&-element {
				width: 40px !important;
				height: 40px !important;
				border-radius: 4px !important;
				-webkit-border-radius: 4px !important;
				-moz-border-radius: 4px !important;
				-ms-border-radius: 4px !important;
				-o-border-radius: 4px !important;
				min-width: auto !important;
				padding: 4px !important;
				box-shadow: none !important;
				transition: 0.3s;
				-webkit-transition: 0.3s;
				-moz-transition: 0.3s;
				-ms-transition: 0.3s;
				-o-transition: 0.3s;
			}

			&-page {
				background-color: $whiteColor !important;
				border: 1px solid #dce0e6 !important;
				color: $darkGrayColor !important;
				font-size: 14px;
				font-weight: 700;

				&.p-highlight {
					border-color: $mainColor !important;
					color: $mainColor !important;
				}
			}

			&-first,
			&-prev,
			&-next,
			&-last {
				background-color: $whiteColor !important;
				border: 1px solid #dce0e6 !important;
				color: $darkGrayColor !important;

				&.p-disabled {
					background-color: #919eab !important;
					border-color: #919eab !important;
					color: $textDarkColor !important;
				}

				#{$rtl} & {
					transform: rotate(180deg);
					-webkit-transform: rotate(180deg);
					-moz-transform: rotate(180deg);
					-ms-transform: rotate(180deg);
					-o-transform: rotate(180deg);
				}
			}
		}
	}
}
