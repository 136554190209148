@import "assets/scss/vars.scss";

.customers_says {
	padding: 7px 0 80px;
	position: relative;

	.ellipses1 {
		position: absolute;
		top: 0;
		right: 0;
		transform: translateY(-50%);
		-webkit-transform: translateY(-50%);
		-moz-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		-o-transform: translateY(-50%);
		height: 409px;

		@media (max-width: 575.98px) {
			height: 180px;
		}
	}

	h2 {
		font-size: 44px;
		font-weight: 700;
		color: $mainDarkColor;
		text-transform: capitalize;
		margin-bottom: 40px;

		@media (max-width: 575.98px) {
			font-size: 30px;
		}
	}
}
