@import "assets/scss/vars.scss";

.colorRadio_btn {
	&:not(:last-child) {
		margin-bottom: 12px;
	}

	:global {
		.field-radiobutton {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			gap: 8px;

			label {
				font-size: 14px;
				font-weight: 400;
				color: #101223;
			}

			.p-radiobutton {
				width: 12px;
				height: 12px;

				&-box {
					width: 12px;
					height: 12px;
					border-color: transparent;

					&.p-focus {
						box-shadow: none !important;
					}

					.p-radiobutton-icon {
						width: 8px;
						height: 8px;
					}
				}

				&-checked {
					& + label {
						font-weight: 700;
						color: $mainColor;
					}
				}

				&.red {
					.p-radiobutton-box {
						border-color: #f0454c;
						background-color: #f0454c;

						.p-radiobutton-icon {
							background-color: #f0454c;
						}
					}
				}

				&.green {
					.p-radiobutton-box {
						border-color: #46f0be;
						background-color: #46f0be;

						.p-radiobutton-icon {
							background-color: #46f0be;
						}
					}
				}

				&.gray {
					.p-radiobutton-box {
						border-color: #dde4f0;
						background-color: #dde4f0;

						.p-radiobutton-icon {
							background-color: #dde4f0;
						}
					}
				}

				&.black {
					.p-radiobutton-box {
						border-color: #0f234c;
						background-color: #0f234c;

						.p-radiobutton-icon {
							background-color: #0f234c;
						}
					}
				}

				&.blue {
					.p-radiobutton-box {
						border-color: #29afeb;
						background-color: #29afeb;

						.p-radiobutton-icon {
							background-color: #29afeb;
						}
					}
				}

				&.white {
					.p-radiobutton-box {
						border-color: #b3b3b3;
						background-color: #fff;

						.p-radiobutton-icon {
							background-color: #fff;
						}
					}
				}
			}
		}
	}
}
