@import "assets/scss/vars.scss";

.log_page {
	min-height: 100vh;
	position: relative;

	:global {
		.row {
			min-height: 100vh;

			& > [class*="col-lg-6"] {
				&:last-child {
					@media (max-width: 992.98px) {
						order: -1;
					}
				}
			}
		}
	}

	.log_left {
		background: linear-gradient(
			169.4deg,
			#ffffff0d -6.01%,
			#03cffc0d 36.87%,
			#b977ff0d 78.04%,
			#6508c90d 103.77%
		);
		padding: 170px 93px;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;

		@media (max-width: 992.98px) {
			padding: 60px 120px 80px;
		}

		@media (max-width: 575.98px) {
			padding: 60px 20px 80px;
		}

		.log_img {
			width: auto;
			max-width: 100%;
			margin: 0 auto 56px;
		}

		h2 {
			font-size: 28px;
			font-weight: 700;
			color: $mainDarkColor;
			text-align: center;
			margin-bottom: 23px;
		}

		p {
			font-size: 16px;
			font-weight: 400;
			color: $blueTextBodyColor;
			text-align: center;
			letter-spacing: 2px;
			margin-bottom: 0;
		}
	}

	.log_form {
		height: 100%;
		padding: 170px 120px;
		display: flex;
		flex-direction: column;
		justify-content: center;

		@media (max-width: 992.98px) {
			padding: 120px 120px 60px;
		}

		@media (max-width: 575.98px) {
			padding: 120px 20px 60px;
		}

		.emoji {
			width: auto;
			max-width: 36px;
			margin-bottom: 5px;
		}

		&_head {
			margin-bottom: 45px;

			h1 {
				font-size: 36px;
				font-weight: 700;
				color: $mainDarkColor;
				margin-bottom: 35px;
			}

			.phone_icon {
				display: block;
				width: 100%;
				max-width: 144px;
				margin: 0 auto 36px;
			}

			.verification_code {
				h5 {
					font-size: 16px;
					font-weight: 600;
					color: $secondaryColor;
					margin-bottom: 5px;
					text-align: center;
				}

				p {
					font-size: 16px;
					font-weight: 600;
					color: $mainColor;
					text-align: center;
					margin-bottom: 0;
				}
			}
		}

		.inputs_wrapper {
			margin-bottom: 37px;
		}

		.forget_pass {
			font-size: 16px;
			font-weight: 500;
			color: $mainColor;
			transition: 0.3s;
			-webkit-transition: 0.3s;
			-moz-transition: 0.3s;
			-ms-transition: 0.3s;
			-o-transition: 0.3s;
			display: flex;
			margin-bottom: 50px;

			&:hover {
				text-decoration: underline !important;
			}
		}

		.have_account {
			margin-top: 19px;
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 5px;

			& > * {
				font-size: 16px;
				font-weight: 600;
			}

			span {
				color: $secondaryColor;
			}

			a {
				color: $mainColor;
				text-transform: capitalize;
				transition: 0.3s;
				-webkit-transition: 0.3s;
				-moz-transition: 0.3s;
				-ms-transition: 0.3s;
				-o-transition: 0.3s;

				&:hover {
					text-decoration: underline !important;
				}
			}
		}
	}
}
