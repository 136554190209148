@import "assets/scss/vars.scss";

.bannar {
	width: 100%;
	min-height: 261px;
	background-color: $yellowColor;
	border-radius: 8px;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	-ms-border-radius: 8px;
	-o-border-radius: 8px;
	overflow: hidden;
	margin-bottom: 32px;
}
