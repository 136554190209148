@import "assets/scss/vars.scss";

.desc_reviews {
	:global {
		.p-tabview {
			&-nav {
				background-color: transparent !important;
				border-bottom: 0 !important;
				justify-content: center;

				.p-unselectable-text {
					.p-tabview-nav-link {
						padding: 12px 50px;
						background-color: transparent;
						border: 0 !important;
						font-size: 16px;
						font-weight: 700;
						color: $textDarkColor;

						&:focus {
							box-shadow: none !important;
						}
					}

					&:hover {
						.p-tabview-nav-link {
							background-color: transparent !important;
							color: $textDarkColor !important;
						}
					}

					&.p-highlight {
						.p-tabview-nav-link {
							background-color: transparent;
							color: $mainColor;
						}

						&:hover {
							.p-tabview-nav-link {
								background-color: transparent !important;
								color: $mainColor !important;
							}
						}
					}
				}

				.p-tabview-ink-bar {
					height: 2px;
					background-color: $mainColor;
					border-radius: 5px;
					-webkit-border-radius: 5px;
					-moz-border-radius: 5px;
					-ms-border-radius: 5px;
					-o-border-radius: 5px;
					-webkit-backdrop-filter: blur(5px);
					backdrop-filter: blur(5px);
				}
			}

			&-panels {
				background-color: transparent !important;
				border: 1px solid #dadada;
				padding: 0;
			}
		}
	}

	.description_body {
		padding: 40px 96px;

		@media (max-width: 575.98px) {
			padding: 20px;
		}
	}

	.reviews_body {
		padding: 32px;

		@media (max-width: 575.98px) {
			padding: 20px;
		}
	}
}
