// DIRECTION
$ltr: "body[dir='ltr']";
$rtl: "body[dir='rtl']";

// COLORS
$mainColor: #c4a140;
$secondaryColor: #292731;

$darkBlueColor: #161966;
$mainDarkColor: #19255b;
$bodyTextColor: #4c4c60;

$grayColor: #455360;
$lightGrayColor: #7b8389;

$whiteColor: #fff;
$blackColor: #000;

$SecondaryGrayColor: #ced6e1;
$SecondaryLightGrayColor: #e1e1ed;

$darkColor: #202326;

$goldColor: #efe1c5;
$blueTextBodyColor: #626694;

$BgGrayColor: #f8f9fc;

$bodyTextMutedColor: #808b9a;

$gray500Color: #a0aec0;

$darkGrayColor: #212b36;

$textDarkColor: #020e1f;

$greenColor: #3eb290;

$textColor: #252b42;

$lighDarkColor: #31373d;

$textLightDarkColor: #19313a;

$lightGoldColor: #faf5ec;

$yellowColor: #f5ebd8;

$midGrayColor: #f1f5fd;

$mainDarkBlueColor: #1b1d4d;

$orangeColor: #ffab0a;

$blueColor: #283367;

$redColor: #f44e4e;

$lightColor: #fdfdfd;

$blackMedColor: #191919;

$grayLightColor: #d9d9d9;

// MIXINS
// Radius
@mixin borderRadius($radius) {
	border-radius: $radius;
	-webkit-border-radius: $radius;
	-moz-border-radius: $radius;
	-ms-border-radius: $radius;
	-o-border-radius: $radius;
}

// Transition
@mixin transition($val) {
	transition: $val;
	-webkit-transition: $val;
	-moz-transition: $val;
	-ms-transition: $val;
	-o-transition: $val;
}

// Transform
@mixin transform($val) {
	transform: $val;
	-webkit-transform: $val;
	-moz-transform: $val;
	-ms-transform: $val;
	-o-transform: $val;
}
